import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

const terms = () => {
  return (
    <Layout>
      <Helmet>
        <title>Terms & Conditions | InsideHero</title>
      </Helmet>

      <div className="container">
        <section className="terms-section">
          <h1>Privacy & terms</h1>

          <h2>1.INFORMATION</h2>
          <p>
            Your use of InsideHero’s services, including the services InsideHero
            makes available through this website, including this website (the
            “Site” or the “InsideHero Site”) and any content (“InsideHero
            Content”) made available through this website (collectively the
            “Services” ) is governed by these terms and conditions (the
            “Terms”). Please read these terms carefully before using the
            Services.
          </p>

          <p>
            You acknowledge and agree that by accessing or using the InsideHero
            site or services, or posting or accessing any content on the site or
            through the services, you are indicating that you have read,
            understand and agree to be bound by these terms. if you do not agree
            to these terms, then you have no right to access or use the site,
            services or InsideHero content.
          </p>

          <p>
            If you are using the Services on behalf of an organization, you are
            agreeing to these Terms for that organization and promising that you
            have the authority to bind that organization to these terms. In that
            case, “you” and “your” will refer to that organization.
          </p>

          <h2>2.LICENSE GRANTED BY InsideHero </h2>

          <p>
            InsideHero gives you a personal, royalty-free, non-assignable and
            non-exclusive license to use the software provided to you by
            InsideHero as part of the Services provided to you by InsideHero.
            Please note that you may use this software for internal business
            purposes only, and only in the manner permitted by the Terms. You
            may not license, sublicense, sell, resell, transfer, assign,
            distribute or otherwise commercially exploit or make available to
            any third party the Services or the content provided by or on behalf
            of InsideHero through the Services (the content) in any way, except
            as permitted by the Terms.
          </p>

          <h2>3.ORGANIZATION REGISTERED USER</h2>
          <p>
            In the course of using the Services, you may be required to provide
            InsideHero personally identifiable information, including contact
            information, username and password (“Credentials”). InsideHero
            handles such information with the utmost attention, care and
            security. Nonetheless, you, not InsideHero, shall be responsible for
            maintaining and protecting your Credentials in connection with the
            Services. If your contact information, or other information relating
            to your username or password changes, you must notify InsideHero
            promptly and keep such information current. You are solely
            responsible for any activity using your Credentials, whether or not
            you authorized that activity. You should immediately notify
            InsideHero of any unauthorized use of your Credentials or if your
            email or password has been hacked or stolen. If you discover that
            someone is using your Credentials without your consent, or you
            discover any other breach of security, you agree to notify
            InsideHero immediately. InsideHero reserves the right to delete your
            account at any time without notice.
          </p>

          <h2>4.PERSONAL INFORMATION </h2>
          <p>
            In connection with the use of the Services, we collect user data
            when a user registers with InsideHero.io. The data is stored in the
            UK and the USA and will not be passed to a 3rd party without prior
            consent.
          </p>

          <p>
            To ensure that users get the best experience from using our website,
            we process information about behaviour on the website, including the
            number of total visits, and review invites sent. Cookies are used on
            the website to monitor parts of the website the user has previously
            visited. The information will not be processed in a way that can
            trace the behaviour of a specific user.
          </p>

          <p>
            You agree to comply with obligations under GDPR Data protection.
            InsideHero will only act by the instruction of you regarding the
            provided personal data about the Consumers.
          </p>

          <h2>5.SAFEGUARDING YOUR INFORMATION</h2>
          <p>
            To protect your information our credit card processing vendor uses
            SSL technology to secure transactions. Our vendor is certified as
            compliant with card association security initiatives, like the Visa
            Cardholder Information Security and Compliance (CISP), MasterCard
            (SDP), and Paypal.
          </p>

          <p>
            InsideHero accounts require a username and password to login. You
            must keep your username and password secure, and never disclose it
            to a third party. Passwords are encrypted, InsideHero can’t resend
            forgotten passwords, InsideHero can only reset them.
          </p>

          <h2>6.USER-GENERATED CONTENT</h2>
          <p>
            You acknowledge and agree that you are solely responsible for all
            User Content that you make available through the Site or Services.
            Accordingly, you represent and warrant that: (i) you either are the
            sole and exclusive owner of all User Content that you make available
            through the Site or Services or you have all rights, licenses,
            consents and releases that are necessary to grant to InsideHero the
            rights in such User Content, as contemplated under these Terms; and
            (ii) neither the User Content nor your accessing, posting,
            submission or transmittal of the User Content or InsideHero’s use of
            the User Content (or any portion thereof) on, through or by means of
            the Site and the Services will infringe, misappropriate or violate a
            third party’s patent, copyright, trademark, trade secret, moral
            rights or other intellectual property rights, or rights of publicity
            or privacy, or result in the violation of any applicable law or
            regulation.
          </p>

          <p>
            InsideHero reserves the right (but shall have no obligation) to
            remove any or all User Content from the Services in its sole
            discretion. You agree to immediately take down any User Content that
            violates the Terms, including pursuant to a take down request from
            InsideHero. In the event that you elect not to comply with a request
            from InsideHero to take down certain User Content, InsideHero
            reserves the right to directly take down such User Content.
          </p>

          <h2>7.Third Party Services/Materials</h2>
          <p>
            You may be able to access or use third party services, resources,
            content or information (“Third Party Materials”) via InsideHero. By
            using InsideHero to find material on the Internet, you instruct
            InsideHero to present portions of the data sources that you have
            selected. You acknowledge sole responsibility for and assume all
            risk arising from your access to or use of any such Third Party
            Materials and InsideHero disclaims any liability that you may incur
            arising from your access to or use of such Third Party Materials or
            User Content via InsideHero. You acknowledge and agree that
            InsideHero: (a) is not responsible for the availability or accuracy
            of such Third Party Materials or the products or services on or
            available from such Third Party Materials; (b) has no liability to
            you or any third party for any harm, injuries or losses suffered as
            a result of your access to or use of such Third Party Materials; (c)
            does not make any promises to remove Third Party Materials from
            being accessed through the Services. Your ability to access or link
            to Third Party Materials does not imply any endorsement by
            InsideHero of Third Party Materials or any such third party
            services; and (d) has no responsibility or liability for the
            deletion or failure to store any Third Party Materials maintained or
            transmitted through use of the Services. You further acknowledge
            that you are solely responsible for backing up and making copies of
            any Third Party Material that you wish to preserve.
          </p>

          <h2>8.COMPLIANCE WITH 3RD PARTY REVIEW SITE POLICIES</h2>
          <p>
            Business using the InsideHero platform agree to adhere to the Terms
            of Service of all major review sites. This includes but not limited
            to Google, Facebook, Yelp, and other review sites.
          </p>

          <p>
            <bold>Review gating:</bold> Businesses using InsideHero cannot
            implement any form of review gating. Review gating is a process
            where companies send a message to customers asking if their
            experience was positive or negative. Those who had a positive
            experience are directed to leave an online review. Those who had a
            negative experience are typically sent to a form to leave comments
            that are not made public. When soliciting reviews using the
            InsideHero platform, businesses must provide all recipients the same
            options to provide feedback, regardless of their sentiment. This
            applies to review requests sent via standard review templates,
            custom email templates, and surveys.
          </p>

          <p>
            Sites prohibiting solicitation of reviews: Businesses using
            InsideHero will fully comply with sites such as Yelp that prohibit
            review solicitation, and will not misuse the InsideHero platform to
            bypass review sites’ policies.
          </p>

          <p>
            Failure to comply with 3rd Party Review site policies: If a business
            attempts to circumvent the Terms of Service of 3rd party review
            sites, InsideHero will notify the business and allow them 30 days
            take corrective action. Should the business fail to comply within
            the 30-day notice period, their account will be suspended from
            InsideHero.
          </p>

          <h2>9.THIRD PARTY SOFTWARE</h2>
          <p>
            The Services incorporate certain third party software (“Third Party
            Software”), which is licensed subject to the terms and conditions of
            the third party licensing such Third Party Software. Nothing in
            these Terms limits your rights under, or grants you rights that
            supersede, the terms and conditions of any applicable license for
            such Third Party Software.
          </p>

          <h2>10. RESTRICTIONS ON USE</h2>
          <ul>
            <p>
              You agree not to do any of the following while using the Site,
              Services or InsideHero Content:
            </p>
            <li>
              Access, post, submit or transmit any text, graphics, images,
              software, music, audio, video, information or other material that:
              (i) infringes, misappropriates or violates a third party’s patent,
              copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy;
              (ii) violates, or encourages any conduct that would violate, any
              applicable law or regulation or would give rise to civil
              liability; (iii) is fraudulent, false, misleading or deceptive;
              (iv) is defamatory, obscene, vulgar or offensive; (v) constitutes
              pornography; (vi) promotes discrimination, bigotry, racism,
              hatred, harassment or harm against any individual or group; (vii)
              is violent or threatening or promotes violence or actions that are
              threatening to any other person; or (viii) promotes illegal or
              harmful activities or substances (including, but not limited to
              activities that promote or provide instructional information
              regarding the manufacture or purchase of illegal weapons or
              illegal substances).
            </li>
            <li>
              Use, display, mirror, frame or utilize framing techniques to
              enclose the Site or Services, or any individual element or
              materials within the Site or Services, InsideHero’s name, any
              InsideHero trademark, logo or other proprietary information, the
              content of any text or the layout and design of any page or form
              contained on a page, without InsideHero’s express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Site or
              Services, InsideHero’s computer systems, or the technical delivery
              systems of InsideHero’s providers;
            </li>
            <li>
              Attempt to probe, scan, or test the vulnerability of any
              InsideHero system or network or breach any security or
              authentication measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise
              circumvent any technological measure implemented by InsideHero or
              any of InsideHero’s providers or any other third party (including
              another user) to protect the Site, Services or InsideHero Content;
            </li>
            <li>
              Attempt to access or search the Site, Services or InsideHero
              Content or download InsideHero Content from the Site or Services
              through the use of any engine, software, tool, agent, device or
              mechanism (including spiders, robots, crawlers, data mining tools
              or the like) other than the software and/or search agents provided
              by InsideHero or other generally available third party web
              browsers (such as Microsoft Internet Explorer, Mozilla Firefox,
              Safari or Opera);
            </li>
            <li>
              Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters or other form of
              solicitation;
            </li>
            <li>
              Use any meta tags or other hidden text or metadata utilizing an
              InsideHero trademark, logo URL or product name without
              InsideHero’s express written consent;
            </li>
            <li>
              Use the Site, Services or InsideHero Content for the purpose of
              bringing an intellectual property infringement claim against
              InsideHero or for the purpose of creating a product or service
              competitive with the Services.
            </li>
            <li>
              Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Site, Services or InsideHero Content to send altered,
              deceptive or false source- identifying information;
            </li>
            <li>
              Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Site, Services or
              InsideHero Content;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any
              user, host or network connected to the Site or Services,
              including, without limitation, sending a virus, overloading,
              flooding, spamming, or mail-bombing the Site;
            </li>
            <li>
              Collect or store any personally identifiable information from the
              Site or Services from other users of the Site or Services without
              their express permission;
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ul>

          <p>
            InsideHero will have the right to investigate and prosecute
            violations of any of the above, including intellectual property
            rights infringement and Site and Services security issues, to the
            fullest extent of the law. InsideHero may involve and cooperate with
            law enforcement authorities in prosecuting users who violate these
            Terms. You acknowledge that InsideHero has no obligation to monitor
            your access to or use of the Site, Services or InsideHero Content or
            to review or edit any User Content, but has the right to do so for
            the purpose of operating the Site and Services, to ensure your
            compliance with these Terms, or to comply with applicable law or the
            order or requirement of a court, administrative agency or other
            governmental body. InsideHero reserves the right, at any time and
            without prior notice, to remove or disable access to any InsideHero
            Content and any User Content, that InsideHero, in its sole
            discretion, considers to be in violation of these Terms or otherwise
            harmful to the Site or Services.
          </p>

          <h2>11.UNAUTHORIZED ACCESS </h2>
          <p>
            We do not allow access to our dashboard from direct competitors and
            their employees. If we find that access has been gained by a
            competitor or employee(s) of a competitor we reserve the right to
            charge £100,000 GBP per month for access.{" "}
          </p>

          <h2>11.FEEDBACK</h2>
          <p>
            We welcome and encourage you to provide feedback, comments and
            suggestions for improvements to the Site and Services (“Feedback”).
            You may submit Feedback by emailing us at support@insidehero.io You
            acknowledge and agree that all Feedback will be the sole and
            exclusive property of InsideHero and you hereby irrevocably assign
            to InsideHero and agree to irrevocably assign to InsideHero all of
            your right, title, and interest in and to all Feedback, including
            without limitation all worldwide patent rights, copyright rights,
            trade secret rights, and other proprietary or intellectual property
            rights therein. At InsideHero’s request and expense, you will
            execute documents and take such further acts as InsideHero may
            reasonably request to assist InsideHero to acquire, perfect, and
            maintain its intellectual property rights and other legal
            protections for the Feedback.
          </p>

          <h2>12.OWNERSHIP</h2>
          <p>
            The Site, Services and InsideHero Content are protected by
            copyright, trademark, and other laws of the United Kingdom and
            foreign countries. Except as expressly provided in these Terms,
            InsideHero and its licensors exclusively own all right, title and
            interest in and to the Site, Services and InsideHero Content,
            including all associated intellectual property rights. You will not
            remove, alter or obscure any copyright, trademark, service mark or
            other proprietary rights notices incorporated in or accompanying the
            Site, Services or InsideHero Content. InsideHero claims no ownership
            interest in any Third Party Materials and expressly disclaims any
            liability concerning those materials.
          </p>

          <h2>13.TRADEMARK</h2>
          <p>
            All trademarks, service marks, logos, trade names and any other
            proprietary designations of InsideHero used herein are trademarks or
            registered trademarks of InsideHero. Any other trademarks, service
            marks, logos, trade names and any other proprietary designations are
            the trademarks or registered trademarks of their respective parties.
          </p>

          <h2>14.CHANGES TO SERVICES OR TERMS</h2>
          <p>
            InsideHero reserves the right, in its sole discretion, to modify,
            discontinue or terminate the Site or Services or to modify these
            Terms, at any time. Changes to the Services may include the
            modification or discontinuation of any “review aggregation” services
            currently offered as part of the Services. If we modify these Terms,
            we will post the modification on the Site or otherwise provide you
            with notice of the modification. By continuing to access or use the
            Site or Services after we have posted a modification to these Terms
            or have provided you with notice of a modification, you are
            indicating that you agree to be bound by the modified Terms. If the
            modified Terms are not acceptable to you, your only recourse is to
            cease using the Site and Services. These Terms may only be modified
            in writing as set forth in this paragraph and may not be modified
            orally. Please visit this page regularly to review these Terms for
            any changes.
          </p>

          <h2>15.TERMINATION</h2>

          <p>
            Without limiting other remedies, InsideHero may at any time suspend,
            terminate, or refuse to provide you with access to the Site or
            Services. In addition, InsideHero may notify authorities or take any
            actions it deems appropriate, without notice to you, if InsideHero
            suspects or determines, in its own discretion, that you may have or
            there is a significant risk that you have (i) failed to comply with
            any provision of these Terms and Conditions or any policies or rules
            established by InsideHero; or (ii) engaged in actions relating to or
            in the course of using the Site or Services that may be illegal or
            cause liability, harm, embarrassment, harassment, abuse or
            disruption for you, InsideHero users, InsideHero or any other third
            parties or the Site or Services.
          </p>

          <p>
            Without limiting any other terms of these Terms and Conditions, you
            may stop using the Site and Services at any time.
          </p>

          <p>
            After any termination, you understand and acknowledge that we will
            have no further obligation to provide the Site or Services and all
            licenses and other rights granted to you by these Terms and
            Conditions will immediately cease. InsideHero will not be liable to
            you or any third party for termination of the Site or Services or
            termination of your use of either. Any suspension, termination or
            cancellation will not affect your obligations to InsideHero under
            these Terms and Conditions (including, without limitation,
            proprietary rights and ownership, indemnification and limitation of
            liability), which by their sense and context are intended to survive
            such suspension, termination or cancellation.
          </p>

          <h2>16.PRICING AND BILLING</h2>
          <p>
            Please see contract for information regarding pricing and billing
            for these Services. If you have any questions about InsideHero’s
            billing policies, please contact InsideHero at support@insidehero.io
          </p>

          <h2>17.WARRANTIES</h2>
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES ARE
            AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED “AS IS” AND “AS
            AVAILABLE.”
          </p>
          <p>
            InsideHero, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS MAKE
            NO EXPRESS WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES REGARDING
            THE SERVICES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT
            LIMITING THE GENERALITY OF THE FOREGOING, InsideHero, ITS
            SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR
            WARRANT TO YOU THAT: (A) YOUR USE OF THE SERVICES WILL MEET YOUR
            REQUIREMENTS, (B) YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED,
            TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA PROVIDED
            THROUGH THE SERVICES WILL BE ACCURATE.
          </p>
          <p>
            NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT InsideHero’S WARRANTY
            OR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR
            LIMITED BY APPLICABLE LAW.
          </p>

          <h2>18.INDEMNIFICATION</h2>
          <p>
            You agree to defend, indemnify, and hold InsideHero, its officers,
            directors, employees and agents, harmless from and against any
            claims, liabilities, damages, losses, and expenses, including,
            without limitation, reasonable legal and accounting fees, arising
            out of or in any way connected with User Content, your access to or
            use of the Site, Services or InsideHero Content, or your violation
            of these Terms.
          </p>

          <h2>19.LIMITATION OF LIABILITIES</h2>
          <p>
            Neither party shall be liable for any loss of profits or revenues,
            loss of business opportunity, loss of goodwill or reputation, loss
            of data or any indirect, consequential or special Loss whatsoever.
            Neither party excludes any Loss in respect of personal injury or
            death, fraudulent misrepresentation or any other Loss that may not
            be lawfully excluded or limited under English law.
          </p>

          <h2>20.GOVERNING LAW, NON-WAIVER, ASSIGNMENT</h2>
          <p>
            These Terms, together with our Privacy and Security Policy at
            privacy constitutes the entire agreement between the parties
            relating to the Services and all related activities. These Terms
            shall not be modified except in writing signed by both parties or by
            a new posting of these Terms issued by us. If any part of these
            Terms is held to be unlawful, void, or unenforceable, that part
            shall be deemed severed and shall not affect the validity and
            enforceability of the remaining provisions. The failure of
            InsideHero to exercise or enforce any right or provision under these
            Terms shall not constitute a waiver of such right or provision. Any
            waiver of any right or provision by InsideHero must be in writing
            and shall only apply to the specific instance identified in such
            writing. You may not assign these Terms, or any rights or licenses
            granted hereunder, whether voluntarily, by operation of law, or
            otherwise without our prior written consent. These Terms and any
            action related thereto will be governed by the laws of England,
            United Kingdom.
          </p>

          <h2>21.CONTACT US</h2>
          <p>
            If you have any questions about these Terms, please contact
            InsideHero at support@insidehero.io
          </p>

          <p>Last updated: 10th Jan 2022</p>
        </section>
      </div>
    </Layout>
  )
}

export default terms
